const API_PREFIX = 'learn'

export const urls = {
    profile: {
        endpoint: `${API_PREFIX}/user`,
        type: 'GET'
    },
    courses: {
        endpoint: `${API_PREFIX}/courses/`,
        type: 'GET'
    },
    course: {
        endpoint: `${API_PREFIX}/course/`,
        type: 'GET'
    },
    topic: {
        endpoint: `${API_PREFIX}/course/`,
        type: 'GET'
    },
    heartBeat: {
        endpoint: `${API_PREFIX}/account/hb/`,
        type: 'GET'
    },
    userInfo: {
        endpoint: `${API_PREFIX}/userinfo/`,
        type: 'GET'
    },
    login: {
        endpoint: `${API_PREFIX}/account/login/`,
        type: 'POST'
    },
    logout: {
        endpoint: `${API_PREFIX}/account/logout/`,
        type: 'POST'
    },
    repository: {
        endpoint: `${API_PREFIX}/course/`,
        type: 'GET'
    },
    openIdClient: {
        endpoint: `${API_PREFIX}/ocid/`,
        type: 'GET'
    },
    createSandbox: {
        endpoint: `${API_PREFIX}/sandbox/`,
        type: 'POST'
    },
    getSandboxStatus: {
        endpoint: `${API_PREFIX}/sandbox/`,
        type: 'GET'
    },
}

export default urls
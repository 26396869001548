import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: WorkspaceStatusInfo[] = []
const workspaceStatusSlice = createSlice({
	name: 'WorkspaceStatus',
	initialState,
	reducers: {
		addOrUpdateWorkspaceStatus: (
			state,
			action: PayloadAction<WorkspaceStatusInfo>,
		) => {
			const workspaceStatus = state.find(
				(item: WorkspaceStatusInfo) =>
					item.course === action.payload.course &&
					item.topic === action.payload.topic,
			)
			if (workspaceStatus) {
				workspaceStatus.status = action.payload.status
				workspaceStatus.workspaceUrl = action.payload.workspaceUrl
				workspaceStatus.startProgressPercentage =
					action.payload.startProgressPercentage
				workspaceStatus.endProgressPercentage = action.payload.endProgressPercentage
			} else {
				state.push(action.payload)
			}
			return state
		},
	},
})
export const { addOrUpdateWorkspaceStatus } = workspaceStatusSlice.actions
export default workspaceStatusSlice.reducer

import axios, { Method } from 'axios'
import operations from './operations';
import urls from './urls';

const getCSRFToken = () => {
	const cookies = document.cookie.split('; ');
	const csrfTokenIndex = cookies.findIndex((item) => item.startsWith('learn_csrftoken'));

	if (csrfTokenIndex >= 0) {
		return cookies[csrfTokenIndex].replace('learn_csrftoken=', '');
	}

	return '';
};

export default async function learnBackendRequestHandler(
	operation: operations,
	data?: any,
	token?: string,
	appendURL?: string
) {
	const endpointDetails = urls[operation]
	const csrfToken = getCSRFToken()
	if (endpointDetails) {
		const headers: any = {
			Accept: 'application/json',
			'X-CSRFToken': csrfToken,
			'Content-type': 'application/json',
		}
		if (token) {
			headers.Authorization = `Bearer ${token}`
		}
		return axios({
			method: endpointDetails.type as Method,
			withCredentials: true,
			headers,
			url: `${process.env.REACT_APP_BASE_API_URL}${endpointDetails.endpoint}${appendURL || ''}`,
			data,
		})
	}
	throw new Error('Invalid Operation')
}

import { configureStore } from '@reduxjs/toolkit';
import courseReducer from './courseSlice'
import coursesReducer from './coursesSlice'
import workspaceStatusReducer from './workspaceStatusSlice'

export const store = configureStore({
	reducer: {
		course: courseReducer,
		courses: coursesReducer,
		workspaceStatus: workspaceStatusReducer,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

import { OidcClientSettings, OidcClient, CreateSigninRequestArgs } from 'oidc-client-ts'
import { createContext } from "react";
import learnBackendRequestHandler from "./requestHandler";

let oidcClientSettings: OidcClientSettings
let oidcClient: OidcClient
let createSigninRequestArgs: CreateSigninRequestArgs


export async function initOIDC() {
	if (!oidcClientSettings && !oidcClient && !createSigninRequestArgs) {
		try {
			const clientID = ((await learnBackendRequestHandler('openIdClient')).data as any)
				.client_id
			// the following settings are for manual login
			oidcClientSettings = {
				authority: `${process.env.REACT_APP_SECDIM_ID_BACKEND}openid`,
				client_id: clientID, // need to get this in dev env somehow...
				redirect_uri: window.location.origin,
				post_logout_redirect_uri: `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/logout`,
				response_type: 'code',
				scope: 'openid email',
				filterProtocolClaims: true,
				loadUserInfo: true,
			}
			createSigninRequestArgs = {
				redirect_uri: window.location.origin,
				response_type: 'code',
				scope: 'openid email',
			}
			oidcClient = new OidcClient(oidcClientSettings)
		} catch {
			// eslint-disable-next-line no-console
			console.error("API is failing to list oclient id")
		}
	}
}

export function handleSignIn() {
	sessionStorage.removeItem("silentLoginAlreadyAttempted")
	sessionStorage.setItem('redirectURI', window.location.href)
	oidcClient
		.createSigninRequest(createSigninRequestArgs)
		.then(req => {
			window.location.href = req.url // nosemgrep
		})
		.catch(err => {
			// eslint-disable-next-line no-console
			console.error(err)
		})
}


export const OpenIDContext = createContext({
	authenticated: false,
	profile: {
		firstName: '',
		lastName: '',
		username: '',
		email: '',
		guid: '',
		isSubscribed: false,
		subscriptionType: '',
		restrictions: {
			hasCSEAccess: false,
			hasTopicAccess: false,
		},
	},
})

export function checkAuth() { }

export async function codeResponse(url: string) {
	await initOIDC()
	return oidcClient.processSigninResponse(url)
}
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum LoadButtonWrapper {
    Default = 'default',
    Hidden = 'hidden',
}

export enum TopicSubscription {
    Free = 'FR',
    Paid = 'PA',
}

export enum TagName {
    Featured = "Featured"
}

export enum TopicKind {
    Video = 'VI',
    Text = 'TX',
    Lab = 'LA',
    Certificate = 'CE'
}

export enum CourseType {
    Python = 'python',
    Java = 'java',
    Javascript = 'javascript',
    Typescript = 'typescript',
    CSharp = 'csharp',
    Go = 'go',
    Ruby = 'ruby',
    PHP = 'php',
    Docker = 'docker',
    Design = 'design',
    Fuzzing = 'fuzzing',
    AWS = 'aws',
    Solidity = 'solidity',
    Kubernetes = 'kubernetes',
    OpenAPI = 'openapi'
}

export enum WorkspaceStatus {
	Pending = 'pending',
	Creating = 'creating',
	Started = 'started',
	Created = 'created',
	Starting = 'starting',
	Ready = 'ready',
	Deleted = 'deleted',
	Error = 'error',
}